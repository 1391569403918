<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card elevation="0" tile>
        <v-card-title class="grey lighten-4">
          {{ $t("hr.menu.hiring") }}
          <v-spacer />
          <id-button @click="onRefreshData" :loading="table.loading">
            <template #icon>
              <v-icon left>mdi-refresh</v-icon>
            </template>
            {{ $t("buttons.refresh") }}
          </id-button>
        </v-card-title>
        <v-data-table
          :headers="table.headers"
          :items="table.data"
          :options.sync="table.options"
          :server-items-length="table.totalItems"
          :loading="table.loading"
          :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
          class="elevation-1"
        >
          <template slot="item" slot-scope="row">
            <tr :active="row.selected">
              <td class="pl-0">
                <v-list-item v-if="row.item.applicant !== null">
                  <v-list-item-avatar
                    size="32"
                    class="mr-2"
                    :color="row.item.applicant.photoLink ? 'white' : 'primary'"
                  >
                    <v-img
                      v-if="row.item.applicant.photoLink"
                      :src="row.item.applicant.photoLink"
                      :lazy-src="row.item.applicant.photoLink"
                    >
                      <template v-slot:placeholder>
                        <v-layout fill-height align-center justify-center ma-0>
                          <v-progress-circular
                            v-if="isSelectedItemUpdating && selectedRow.id === row.item.id"
                            indeterminate
                            color="grey lighten-5"
                          />
                          <v-progress-circular v-else indeterminate color="grey lighten-5" />
                        </v-layout>
                      </template>
                    </v-img>
                    <template v-else>
                      <v-progress-circular
                        v-if="isSelectedItemUpdating && selectedRow.id === row.item.id"
                        indeterminate
                        color="grey lighten-5"
                      />
                      <span v-else class="white--text font-weight-bold">
                        {{ row.item.applicant.name.substring(0, 1).toUpperCase()
                        }}{{ row.item.applicant.surName.substring(0, 1).toUpperCase() }}
                      </span>
                    </template>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="body-2"
                      >{{ row.item.applicant.name }} {{ row.item.applicant.surName }}</v-list-item-title
                    >
                    <v-list-item-subtitle class="caption">{{ row.item.applicant.email }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </td>

              <td>
                <v-list-item-content>
                  <v-list-item-title class="body-2">{{ row.item.jobPosting.postName }}</v-list-item-title>
                  <v-list-item-subtitle class="caption"> {{ row.item.jobPosting.postTitle }}</v-list-item-subtitle>
                </v-list-item-content>
              </td>
              <td>
                {{ row.item.applicantLastJob }}
              </td>
              <td>
                {{ row.item.applicant.phone || "-" }}
              </td>
              <td class="text-no-wrap">
                {{ row.item.assessmentDate | formattedDate }}
              </td>
              <td>
                <v-tooltip left :color="getColor(row.item.numericAssessmentScore)">
                  <template v-slot:activator="{ on }">
                    <div v-on="on">
                      <v-rating
                        :value="row.item.numericAssessmentScore"
                        readonly
                        dense
                        :color="getColor(row.item.numericAssessmentScore)"
                        half-increments
                        background-color="grey lighten-1"
                      />
                    </div>
                  </template>
                  <span>
                    {{ row.item.numericAssessmentScore }} /
                    {{ $t(`assessment.${getAssessmentText(row.item.numericAssessmentScore).toLowerCase()}`) }}
                  </span>
                </v-tooltip>
              </td>
              <td>
                <v-btn
                  v-if="
                    !row.item.evaluatorAssessment.evaluatorRating &&
                      getInterviewStatus(row.item.evaluatorAssessment.status)
                  "
                  depressed
                  @click.prevent.stop="onEvaluateeEvaluation(row.item)"
                >
                  {{ $t("buttons.evaluatee") }}
                </v-btn>
                <v-btn v-else depressed color="primary" @click.stop="onEvaluatorResult(row.item)">
                  Sonuç
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
        <transition name="slide-in-left">
          <router-view @success:save="onRefreshData" />
        </transition>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import { EMPLOYEE_HIRING_EVALUATION, EVALUATOR_RESULT } from "./query";
  import { INTERVIEW_STATUS } from "../../../base/data";

  export default {
    name: "Hiring",
    components: {},
    data: vm => ({
      INTERVIEW_STATUS,
      isSelectedItemUpdating: false,
      selectedRow: null,
      table: {
        loading: false,
        data: [],
        criteria: {
          employeeId: vm.$store.state.auth.user.id
        },
        options: {},
        pagination: {
          page: 1,
          rowsPerPage: 10,
          sortBy: "assessmentDate",
          descending: true
        },
        totalItems: 0,
        totalPages: 0,
        selected: [],
        headers: [
          {
            text: vm.$t("hr.hiring.table.applicant"),
            align: "left",
            sortable: false,
            value: "applicant.name",
            class: ["primary--text"]
          },

          {
            text: vm.$t("hr.hiring.table.job_posting"),
            align: "left",
            sortable: false,
            value: "jobPosting.postName",
            class: ["primary--text"]
          },

          {
            text: vm.$t("hr.hiring.table.last_job"),
            align: "left",
            sortable: false,
            value: "lastJob",
            class: ["primary--text"]
          },
          {
            text: vm.$t("global.phone"),
            align: "left",
            sortable: false,
            value: "applicant.phone",
            class: ["primary--text"]
          },

          {
            text: vm.$t("hr.hiring.table.assessment_date"),
            align: "left",
            sortable: false,
            value: "assessmentDate",
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.hiring.table.assessment"),
            align: "left",
            sortable: false,
            value: "assessment",
            class: ["primary--text"],
            width: "250"
          },
          {
            text: "",
            align: "left",
            sortable: false,
            value: "",
            class: ["primary--text"],
            width: ""
          }
        ]
      }
    }),
    watch: {
      "table.options": {
        handler(options) {
          let { itemsPerPage, page } = options;
          this.table.pagination.rowsPerPage = itemsPerPage;
          this.table.pagination.page = page;
          this.fetchItems();
        },
        deep: true
      }
    },
    methods: {
      onRefreshData() {
        this.fetchItems();
      },
      async fetchItems() {
        this.table.loading = true;

        await this.$apollo
          .query({
            client: "hiring",
            query: EMPLOYEE_HIRING_EVALUATION,
            variables: {
              criteria: this.table.criteria,
              pagination: this.$helpers.convertPagination(this.table.pagination)
            },
            fetchPolicy: "no-cache"
          })
          .then(({ data, errors }) => {
            if (!data.error || !errors) {
              this.table.data = data.filterByEmployeeWithPaginateJobApplicantAssessment.content.map(item => {
                return {
                  id: item.id,
                  applicant: item.applicant,
                  jobPosting: item.jobPosting,
                  assessment: item.assessment,
                  assessmentDate: item.assessmentDate,
                  applicantLastJob: item.applicantLastJob,
                  applicantLastPositionLevel: item.applicantLastPositionLevel,
                  status: item.status,
                  numericAssessmentScore: item.numericAssessmentScore,
                  evaluatorList: item.evaluatorList.map(row => row.id),
                  evaluatorId: item.evaluatorList.find(row => row.employee.id === this.$store.state.auth.user.id).id,
                  evaluatorAssessment: {
                    evaluatorRating: (
                      item.evaluatorAssessmentResults.find(
                        result => result.evaluatorEmployee.id === this.$store.state.auth.user.id
                      ) || {}
                    ).evaluatorRating,
                    status: item.status
                  }
                };
              });
              this.table.totalItems =
                data.filterByEmployeeWithPaginateJobApplicantAssessment.pageInfo.totalElements || 0;
            }
          })
          .catch(e => {
            this.$helpers.showNotification(e.message);
          })
          .finally(() => {
            this.table.loading = false;
          });
      },

      getRating(assessment) {
        let rating = 1;
        switch (assessment) {
          case "VERY_POOR":
            rating = 1;
            break;
          case "POOR":
            rating = 2;
            break;
          case "NORMAL":
            rating = 3;
            break;
          case "GOOD":
            rating = 4;
            break;
          case "VERY_GOOD":
            rating = 5;
            break;
          default:
            rating = 0;
        }
        return rating;
      },
      getColor(rating) {
        let color = null;
        if (rating >= 4) {
          color = "green darken-2";
        } else if (rating < 4 && rating >= 3) {
          color = "green lighten-1";
        } else if (rating < 3 && rating >= 2) {
          color = "orange lighten-1";
        } else if (rating < 2 && rating >= 1) {
          color = "red lighten-2";
        } else {
          color = "grey";
        }
        return color;
      },
      getAssessmentText(rating) {
        let text = null;
        if (rating >= 4) {
          text = "VERY_GOOD";
        } else if (rating < 4 && rating >= 3) {
          text = "GOOD";
        } else if (rating < 3 && rating >= 2) {
          text = "NORMAL";
        } else if (rating < 2 && rating >= 0) {
          text = "POOR";
        } else {
          text = "NA";
        }

        return text;
      },
      getEvaluatorCompleted(evaluators) {
        return evaluators ? evaluators.filter(item => item.evaluatorAssessment).length : 0;
      },
      getEvaluatorCompletedValue(evaluators) {
        let max = evaluators.length;
        let completed = evaluators.filter(item => item.evaluatorAssessment).length;
        let total = (100 / max) * completed;
        return isNaN(total) ? 0 : total.toFixed(0);
      },
      getInterviewStatus(status) {
        return status !== INTERVIEW_STATUS.DONE || status !== INTERVIEW_STATUS.CANCELLED;
      },
      onEvaluatorResult(item) {
        let routerParams = { id: this.$helpers.encodeID(item.id) };
        this.$router.push({ name: "hiring_result", params: routerParams });
      },
      onEvaluateeEvaluation(item) {
        let routerParams = { id: this.$helpers.encodeID(item.id) };
        this.$router.push({ name: "hiring_evaluatee", params: routerParams });
      }
    }
  };
</script>

<style scoped></style>
